@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'admiral_cat';
  src: url('../public/fonts/AdmiralCAT.ttf') format('truetype');
}
@font-face {
  font-family: 'ws_regular';
  src: url('../public/fonts/WorkSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ws_bold';
  src: url('../public/fonts/WorkSans-Bold.ttf') format('truetype');
}

a {
  text-decoration: underline;
}

b {
  @apply font-ws_bold;
}

#root {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.scrollbar-no-visible {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-no-visible::-webkit-scrollbar {
  display: none;
}
